import axios, { AxiosRequestConfig } from 'axios'
import { ApiBaseUrl } from "../../constants/constant"

export const AxiosClient = (idToken: string | null) => {
    const headers: AxiosRequestConfig['headers'] = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
    };

    const config: AxiosRequestConfig = {
        baseURL: ApiBaseUrl,
        headers: headers,
    };

    const apiClient = axios.create(config);

    apiClient.interceptors.response.use(
        response => {
            return Promise.resolve(response.data)
        },
        error => {
            return Promise.reject(error);
        }
    );

    return {apiClient};
}

