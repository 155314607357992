import React from "react"
interface CheckIconProps {
  width   : string
  height  : string
  color   : string
}
const CheckIcon:React.FC<CheckIconProps> = ({width,height,color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.4033 4.26344C17.8101 4.67024 17.8101 5.32978 17.4033 5.73658L8.23659 14.9032C8.04124 15.0986 7.77629 15.2083 7.50002 15.2083C7.22375 15.2083 6.9588 15.0986 6.76345 14.9032L2.59678 10.7366C2.18999 10.3298 2.18999 9.67024 2.59678 9.26344C3.00358 8.85664 3.66313 8.85664 4.06992 9.26344L7.50002 12.6935L15.9301 4.26344C16.3369 3.85664 16.9965 3.85664 17.4033 4.26344Z" fill={color}/>
      </svg>
    )
}
export default CheckIcon