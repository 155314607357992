import { manualValidationSchema } from "../../../schema";
import { useFormik } from 'formik'
import toast from "react-hot-toast";
import { manualSearchProps } from "../../../types";
import { useState } from "react";

const ManualSearch: React.FC<manualSearchProps> = ({ isLoading, setIsLoading, recommendTalent }) => {

  const initialValues = {
    expertise: '',
    industries: '',
    experiences: '',
  };

  const { values, errors, touched, handleChange, handleSubmit }: any = useFormik({
    initialValues,
    validationSchema: manualValidationSchema,
    onSubmit: async (values) => {
      const data = {
        expertise: values.expertise,
        industries: values.industries,
        experiences: values.experiences,
        type: "manual"
      }
      recommendTalent(data);
    },
  });
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className='lx-max-w-[1050px] lx-w-full lx-flex lx-items-start lx-gap-[11px] lx-m-[0_auto] lg:lx-flex-col'>
          <div className="lx-block lx-w-full">
            <input value={values.expertise} onChange={handleChange} name="expertise" className="lx-text-[#8C8E91] lx-text-[16px] lx-leading-[28px] lx-font-normal lx-font-heading_font placeholder:lx-text-[#8C8E91] lx-w-full focus:lx-outline-none focus:lx-border-0 lx-p-[10px_12px_10px_24px] lx-bg-[#fff] lx-rounded-[10000px] lx-box-shadow: 0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.02), 0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.01), 0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.01), 0px 22.336px 17.869px 0px rgba(0, 0, 0, 0.01), 0px 41.778px 33.422px 0px rgba(0, 0, 0, 0.01), 0px 100px 80px 0px rgba(0, 0, 0, 0.01)" type="text" placeholder="Expertise area" />
            {errors.expertise && touched.expertise ? <em className='lx-ml-2 lx-flex lx-items-center lx-gap-[3.2px] lx-mt-[6px] lx-not-italic lx-text-[13px] lx-font-normal lx-leading-[16px] lx-text-[#F04438]'>  {errors.expertise}</em> : null}
          </div>
          <div className="lx-block lx-w-full">
            <input value={values.industries} onChange={handleChange} name="industries" className="lx-text-[#8C8E91] lx-text-[16px] lx-leading-[28px] lx-font-normal lx-font-heading_font placeholder:lx-text-[#8C8E91] lx-w-full focus:lx-outline-none focus:lx-border-0 lx-p-[10px_12px_10px_24px] lx-bg-[#fff] lx-rounded-[10000px] lx-box-shadow: 0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.02), 0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.01), 0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.01), 0px 22.336px 17.869px 0px rgba(0, 0, 0, 0.01), 0px 41.778px 33.422px 0px rgba(0, 0, 0, 0.01), 0px 100px 80px 0px rgba(0, 0, 0, 0.01)" type="text" placeholder="Specific industries" />
            {errors.industries && touched.industries ? <em className='lx-ml-2 lx-flex lx-items-center lx-gap-[3.2px] lx-mt-[6px] lx-not-italic lx-text-[13px] lx-font-normal lx-leading-[16px] lx-text-[#F04438]'>  {errors.industries}</em> : null}
          </div>
          <div className="lx-block lx-w-full">
            <input value={values.experiences} onChange={handleChange} name="experiences" className="lx-text-[#8C8E91] lx-text-[16px] lx-leading-[28px] lx-font-normal lx-font-heading_font placeholder:lx-text-[#8C8E91] lx-w-full focus:lx-outline-none focus:lx-border-0 lx-p-[10px_12px_10px_24px] lx-bg-[#fff] lx-rounded-[10000px] lx-box-shadow: 0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.02), 0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.01), 0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.01), 0px 22.336px 17.869px 0px rgba(0, 0, 0, 0.01), 0px 41.778px 33.422px 0px rgba(0, 0, 0, 0.01), 0px 100px 80px 0px rgba(0, 0, 0, 0.01)" type="text" placeholder="Number of Experience" />
            {errors.experiences && touched.experiences ? <em className='lx-ml-2 lx-flex lx-items-center lx-gap-[3.2px] lx-mt-[6px] lx-not-italic lx-text-[13px] lx-font-normal lx-leading-[16px] lx-text-[#F04438]'>  {errors.experiences}</em> : null}
          </div>

          <button disabled={isLoading} type="submit" className='lx-inline-flex lx-items-center lx-gap-[10px] lx-p-[12px_21px] lx-bg-[#007B5E] lx-justify-center lx-shadow-[0px_2.02px_2.214px_0px_rgba(0,82,35,0.08),0px_4.855px_5.32px_0px_rgba(0,82,35,0.06),0px_9.141px_10.017px_0px_rgba(0,82,35,0.05),0px_16.306px_17.869px_0px_rgba(0,82,35,0.04),0px_30.498px_33.422px_0px_rgba(0,82,35,0.03),0px_73px_80px_0px_rgba(0,82,35,0.02)] lx-rounded-[1000px] lx-ml-[auto]  lx-text-[#F7FFFE] [text-shadow:0px_1.5px_1px_rgba(0,0,0,0.08)] lg:lx-w-full'>
            Generate
          </button>
        </div>
      </form>
    </>
  )
}
export default ManualSearch 