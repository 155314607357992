import { AxiosClient } from '../utils/hooks/useAxios';

export const getRecommendTalent = async (payload: any): Promise<any> => {
    let url;
    // if (payload.type === "auto") {
    //     url = `&url=${payload.url}`
    // } else {
    //     url = `&expertise=${payload.expertise}&industries=${payload.industries}&experiences=${payload.experiences}`
    // }
    try {
        const { apiClient } = AxiosClient(null);
        const response = await apiClient.post(`expert-hunt?type=${payload.type}`, payload);
        return response;
    } catch (error) {
        return error;
    }
};
