import './App.css'
import RecommendTalent from './findExperts'
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <>
      <RecommendTalent/>
      <Toaster></Toaster>
    </>
  )
}

export default App
