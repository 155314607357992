const LoaderIcon = () => {
    return (
      <span className="lx-spinner">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M15.0623 10.0006C15.6802 10.0006 16.1914 10.5084 16.0807 11.1197C15.917 12.0245 15.5558 12.8866 15.0178 13.6416C14.2613 14.7033 13.193 15.5 11.9631 15.9198C10.7332 16.3396 9.40345 16.3615 8.16056 15.9824C6.91766 15.6032 5.82408 14.8421 5.0334 13.8059C4.24273 12.7697 3.79468 11.5105 3.75218 10.2051C3.70969 8.89975 4.07488 7.61376 4.79647 6.52779C5.51805 5.44182 6.55979 4.61041 7.77537 4.15034C8.63987 3.82315 9.56141 3.69546 10.4729 3.77059C11.0888 3.82136 11.4442 4.44927 11.2843 5.0495C11.1243 5.64975 10.5054 5.98869 9.88758 6.00078C9.43769 6.00959 8.98983 6.09513 8.56364 6.25644C7.78567 6.55089 7.11896 7.08298 6.65714 7.77801C6.19532 8.47303 5.9616 9.29606 5.9888 10.1315C6.016 10.967 6.30275 11.7729 6.80878 12.436C7.31481 13.0992 8.0147 13.5863 8.81016 13.8289C9.60561 14.0716 10.4567 14.0576 11.2438 13.7889C12.0309 13.5202 12.7146 13.0103 13.1988 12.3309C13.464 11.9586 13.6621 11.5457 13.787 11.111C13.9586 10.514 14.4443 10.0006 15.0623 10.0006Z" fill="url(#paint0_angular_1745_4226)"/>
          <defs>
          <radialGradient id="paint0_angular_1745_4226" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(9.96501 10.0006) scale(6.21615 6.25092)">
          <stop stopColor="#373838" stopOpacity="0"/>
          <stop offset="0.498734" stopColor="#373838" stopOpacity="0.934966"/>
          <stop offset="0.764221" stopColor="#373838"/>
          </radialGradient>
          </defs>
        </svg>
      </span>
    )
}
export default LoaderIcon