import * as Yup from 'yup';

export const manualValidationSchema = Yup.object({
  expertise: Yup.string().required('Expertise area is required'),
  industries: Yup.string().required('Specific industries is required'),
  experiences: Yup.number().required('Number of Experience is required'),
});

export const aiValidationSchema = Yup.object({
  url: Yup.string().required('Linkedin url is required').url("Please enter a valid url"),
});
export const aiTextValidationSchema = Yup.object({
  text: Yup.string().required('Prompt is required'),
});