
import AiStarGanIcon from "../../../assets/icons/AiStarGanIcon";
import SearchIcon from "../../../assets/icons/SearchIcon";
import { aiValidationSchema } from "../../../schema";
import { useFormik } from 'formik'
import toast from "react-hot-toast";
import { aiSearchProps } from "../../../types";
import { useState } from "react";

const AiSearch: React.FC<aiSearchProps> = ({ isLoading, setIsLoading, recommendTalent, }) => {

  const initialValues = {
    url: '',
  };


  const { values, errors, touched, handleChange, handleSubmit }: any = useFormik({
    initialValues,
    validationSchema: aiValidationSchema,
    onSubmit: async (values) => {
      const data = {
        url: values.url,
        type: "auto"
      }
      recommendTalent(data);
    },
  });
  return (
    <>
      <form className="lx-max-w-[1050px] lx-w-full lx-m-[0_auto]" onSubmit={handleSubmit}>
        <div className='lx-w-full lx-relative lx-bg-[#fff] lx-flex lx-items-center lx-gap-[11px] lx-p-[12px_12px_12px_24px] lx-rounded-[10000px] lx-shadow-[0px_2.767px_2.214px_0px_rgba(0,0,0,0.02),0px_6.65px_5.32px_0px_rgba(0,0,0,0.01),0px_12.522px_10.017px_0px_rgba(0,0,0,0.01),0px_22.336px_17.869px_0px_rgba(0,0,0,0.01),0px_41.778px_33.422px_0px_rgba(0,0,0,0.01),0px_100px_80px_0px_rgba(0,0,0,0.01)] xs:lx-flex-col xs:lx-rounded-[20px] xs:lx-items-start xs:lx-p-[15px]'>
          <span className="lx-flex-none xs:lx-absolute xs:lx-top-[15px] xs:lx-left-[15px]">
            <SearchIcon />
          </span>
          <input value={values.url} onChange={handleChange} name="url" className="lx-text-[#8C8E91] lx-text-[16px] lx-leading-[22px] lx-font-normal lx-font-heading_font placeholder:lx-text-[#8C8E91] lx-w-full focus:lx-outline-none focus:lx-border-0 xs:lx-pl-[30px]" type="text" placeholder="Enter linkedin Url" />
          <button disabled={isLoading} type="submit" className='lx-relative lx-overflow-hidden lx-flex-none lx-inline-flex lx-items-center lx-gap-[10px] lx-p-[12px_21px] lx-border lx-bg-[#03A071] lx-shadow-[0px_2.02px_2.214px_0px_rgba(0,82,35,0.08),0px_4.855px_5.32px_0px_rgba(0,82,35,0.06),0px_9.141px_10.017px_0px_rgba(0,82,35,0.05),0px_16.306px_17.869px_0px_rgba(0,82,35,0.04),0px_30.498px_33.422px_0px_rgba(0,82,35,0.03),0px_73px_80px_0px_rgba(0,82,35,0.02)] lx-rounded-[1000px] lx-border-solid lx-border-[#00C667] lx-ml-[auto] lx-justify-center  lx-text-[#F7FFFE] [text-shadow:0px_1.5px_1px_rgba(0,0,0,0.08)] xs:lx-w-full'>
            <em className="lx-flex-none lx-relative lx-z-[99]">
              <AiStarGanIcon width="20" height="20" />
            </em>
            <em className="lx-absolute [&_svg]:lx-w-[74px] [&_svg]:lx-h-[74px] lx-bottom-0 lx-right-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="92" height="48" viewBox="0 0 92 48" fill="none">
                <g opacity="0.6" filter="url(#filter0_f_1742_3464)">
                  <circle cx="77" cy="61" r="37" fill="#007142" />
                </g>
                <defs>
                  <filter id="filter0_f_1742_3464" x="0" y="-16" width="154" height="154" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="20" result="effect1_foregroundBlur_1742_3464" />
                  </filter>
                </defs>
              </svg>
            </em>
            <em className="lx-absolute lx-top-0 lx-left-0">
              <svg xmlns="http://www.w3.org/2000/svg" width="82" height="48" viewBox="0 0 82 48" fill="none">
                <g opacity="0.6" filter="url(#filter0_f_1742_3466)">
                  <circle cx="5" cy="-9" r="37" fill="#3DFFAE" />
                </g>
                <circle cx="21.5" cy="26.5" r="0.5" fill="#F7FFFE" />
                <circle cx="17.5" cy="30.5" r="0.5" fill="#F7FFFE" />
                <g filter="url(#filter1_f_1742_3466)">
                  <circle cx="26" cy="23" r="0.5" fill="#F7FFFE" />
                </g>
                <g filter="url(#filter2_f_1742_3466)">
                  <circle cx="35" cy="28" r="0.5" fill="#F7FFFE" />
                </g>
                <circle cx="22" cy="27" r="0.5" fill="#F7FFFE" />
                <g filter="url(#filter3_f_1742_3466)">
                  <circle cx="33" cy="30" r="0.5" fill="#F7FFFE" />
                </g>
                <circle cx="20.5" cy="21.5" r="0.5" fill="#F7FFFE" />
                <g filter="url(#filter4_f_1742_3466)">
                  <circle cx="23" cy="23.999" r="0.5" fill="#F7FFFE" />
                </g>
                <g filter="url(#filter5_f_1742_3466)">
                  <circle cx="12.5" cy="20.5" r="0.5" fill="#F7FFFE" />
                </g>
                <g filter="url(#filter6_f_1742_3466)">
                  <circle cx="19.5" cy="29.5" r="0.5" fill="#F7FFFE" />
                </g>
                <g filter="url(#filter7_f_1742_3466)">
                  <circle cx="26" cy="18" r="0.5" fill="#F7FFFE" />
                </g>
                <g filter="url(#filter8_f_1742_3466)">
                  <circle cx="25" cy="30" r="0.5" fill="#F7FFFE" />
                </g>
                <g filter="url(#filter9_f_1742_3466)">
                  <circle cx="33" cy="24" r="0.5" fill="#F7FFFE" />
                </g>
                <circle cx="41" cy="21" r="0.5" fill="#F7FFFE" />
                <defs>
                  <filter id="filter0_f_1742_3466" x="-72" y="-86" width="154" height="154" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="20" result="effect1_foregroundBlur_1742_3466" />
                  </filter>
                  <filter id="filter1_f_1742_3466" x="24.5" y="21.5" width="3" height="3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_1742_3466" />
                  </filter>
                  <filter id="filter2_f_1742_3466" x="31.5" y="24.5" width="7" height="7" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_1742_3466" />
                  </filter>
                  <filter id="filter3_f_1742_3466" x="30.5" y="27.5" width="5" height="5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_1742_3466" />
                  </filter>
                  <filter id="filter4_f_1742_3466" x="21.5" y="22.499" width="3" height="3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_1742_3466" />
                  </filter>
                  <filter id="filter5_f_1742_3466" x="10" y="18" width="5" height="5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_1742_3466" />
                  </filter>
                  <filter id="filter6_f_1742_3466" x="17" y="27" width="5" height="5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1" result="effect1_foregroundBlur_1742_3466" />
                  </filter>
                  <filter id="filter7_f_1742_3466" x="24.5" y="16.5" width="3" height="3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_1742_3466" />
                  </filter>
                  <filter id="filter8_f_1742_3466" x="21.5" y="26.5" width="7" height="7" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="1.5" result="effect1_foregroundBlur_1742_3466" />
                  </filter>
                  <filter id="filter9_f_1742_3466" x="31.5" y="22.5" width="3" height="3" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="0.5" result="effect1_foregroundBlur_1742_3466" />
                  </filter>
                </defs>
              </svg>
            </em>
            <span className="lx-relative lx-z-[99] lx-text-[#F7FFFE] [text-shadow:0px_1.5px_1px_rgba(0,0,0,0.08)] lx-text-[14px] lx-leading-6 lx-font-semibold">Generate</span>
          </button>
        </div>
        {errors.url && touched.url ? <em className='lx-flex lx-ml-[30px] lx-items-center lx-gap-[3.2px] lx-mt-[6px] lx-not-italic lx-text-[13px] lx-font-normal lx-leading-[16px] lx-text-[#F04438]'>  {errors.url}</em> : null}

      </form>
    </>
  )
}
export default AiSearch