
interface Copytext {
  clickAction?: () => void;
}
const CopyIcon: React.FC<Copytext> = ({ clickAction }) => {
  return (
    <svg onClick={clickAction} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.25 16.5625H10.125C10.4062 16.5625 10.6875 16.3164 10.6875 16V13.75H12.375V16C12.375 17.2656 11.3555 18.25 10.125 18.25H2.25C0.984375 18.25 0 17.2656 0 16V8.125C0 6.89453 0.984375 5.875 2.25 5.875H4.5V7.5625H2.25C1.93359 7.5625 1.6875 7.84375 1.6875 8.125V16C1.6875 16.3164 1.93359 16.5625 2.25 16.5625ZM7.875 10.9375H15.75C16.0312 10.9375 16.3125 10.6914 16.3125 10.375V2.5C16.3125 2.21875 16.0312 1.9375 15.75 1.9375H7.875C7.55859 1.9375 7.3125 2.21875 7.3125 2.5V10.375C7.3125 10.6914 7.55859 10.9375 7.875 10.9375ZM5.625 10.375V2.5C5.625 1.26953 6.60938 0.25 7.875 0.25H15.75C16.9805 0.25 18 1.26953 18 2.5V10.375C18 11.6406 16.9805 12.625 15.75 12.625H7.875C6.60938 12.625 5.625 11.6406 5.625 10.375Z" fill="#696B6E" />
    </svg>
  )
}
export default CopyIcon
