import React, { useEffect, useRef, useState } from 'react'
import AiSearch from './components/aiSearch'
import AiStarIcon from '../assets/icons/AiStarIcon'
import SearchIcon from '../assets/icons/SearchIcon'
import CloseIcon from '../assets/icons/CloseIcon'
import LoaderIcon from '../assets/icons/LoaderIcon'
import CopyIcon from '../assets/icons/CopyIcon'
import CheckIcon from '../assets/icons/CheckIcon'
import { getRecommendTalent } from '../services'
import ManualSearch from './components/manualSearch'
import { payload } from '../types'
import toast from 'react-hot-toast'
import PromptSeacrh from './components/promptSearch'

const RecommendTalent = () => {
  const [isSearching, setIsSearching] = useState<string>('auto');
  const [resposnse, setResponse] = useState<any>(null);
  const [textResposnse, setTextResponse] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [requestLoadingLevel, setRequestLoadingLevel] = useState<any>(null);
  const responseRef = useRef<HTMLDivElement>(null)



  const recommendTalent = async (payload: any) => {
    setIsLoading(true)
    setResponse(null)
    setTextResponse(null)
    setRequestLoadingLevel(1)

    let timeOutOne = setTimeout(() => { setRequestLoadingLevel(2) }, 3000) // Adding 3s to change the status of request response to preparing talent details
    let timeOutTwo = setTimeout(() => { setRequestLoadingLevel(3) }, 6000) // Adding 6s to change the status of request response to generating email

    const res = await getRecommendTalent(payload)
    setRequestLoadingLevel(4)
    setIsLoading(false)
    toast.dismiss();
    if (res.status == 200) {
      toast.success("Email generated :)")
      payload?.type == 'text' ? setTextResponse(res.data) : setResponse(res)
    } else {
      toast.error(res.response.data.message)
      setResponse(null)
      setRequestLoadingLevel(null)
    }
    clearTimeout(timeOutOne)
    clearTimeout(timeOutTwo)
  }

  const copyResposnse = () => {
    toast.success("Copied")
    // resposnse && navigator.clipboard.writeText(resposnse?.message)
    if (responseRef.current) {
      responseRef?.current?.innerText && navigator.clipboard.writeText(responseRef?.current?.innerText)
    }
  }

  useEffect(() => {
    setResponse(null)
    setRequestLoadingLevel(null)
    setTextResponse(null)
  }, [isSearching])

  useEffect(() => {
    console.log(requestLoadingLevel)
    if (requestLoadingLevel == null) {
      setResponse(null)
      setRequestLoadingLevel(null)
    }
  }, [requestLoadingLevel])

  // Function to strip HTML tags from the response message
  const stripHtmlTags = (html: string) => {
    const temp = document.createElement('div');
    temp.innerHTML = html;

    // Exclude specific tags from stripping
    temp.querySelectorAll('a').forEach(anchor => {
      temp.removeChild(anchor);
      temp.appendChild(document.createTextNode(anchor.textContent || ''));
    });

    return temp.textContent || temp.innerText || '';
  };

  return (
    <div className='lx-h-[calc(100vh-60px)] lx-overflow-hidden lx-max-w-[1512px] lx-w-full lx-m-[30px_auto] lx-bg-[#F7F5F6] lx-shadow-[0px_2.767px_2.214px_0px_rgba(0,0,0,0.02),0px_6.65px_5.32px_0px_rgba(0,0,0,0.03),0px_12.522px_10.017px_0px_rgba(0,0,0,0.04),0px_22.336px_17.869px_0px_rgba(0,0,0,0.04),0px_41.778px_33.422px_0px_rgba(0,0,0,0.05),0px_100px_80px_0px_rgba(0,0,0,0.07)] lx-rounded-[30px] xxxl:lx-w-[calc(100vw-60px)]'>
      <div className='lx-relative lx-p-[32px] lx-flex lx-items-center lx-gap-[20px] xxxl:lx-justify-between lg:lx-flex-col lg:lx-items-start'>
        <h3 className='lx-text-[#4D4F51] lx-text-[18px] lx-leading-[25px] lx-font-medium lx-font-heading_font'>Recommend Talent Scout</h3>
        <div className="lx-ml-[220px] lx-flex lx-relative [background:rgba(110,110,110,0.10)] lx-leading-[3rem] lx-h-[60px] lx-items-center lx-p-1.5 lx-rounded-[999px] xxxl:lx-ml-auto lg:lx-mx-[auto] md:lx-flex-col md:lx-h-auto md:lx-rounded-[20px] xs:lx-w-full">
          <div className='lx-flex-none [&_input:checked~label_svg_path]:lx-fill-[#323335] [&_input:checked~label]:lx-opacity-[1] [&_input:checked~label]:lx-bg-[#fff] [&_input:checked~label]:lx-shadow-[0px_0.775px_1.245px_0px_rgba(0,0,0,0.01),0px_1.862px_2.993px_0px_rgba(0,0,0,0.01),0px_3.506px_5.635px_0px_rgba(0,0,0,0.01),0px_6.254px_10.051px_0px_rgba(0,0,0,0.02),0px_11.698px_18.8px_0px_rgba(0,0,0,0.02),0px_28px_45px_0px_rgba(0,0,0,0.03)] xs:lx-w-full'>
            <input className='lx-hidden' type="radio" id="switchEnhanced" name="switchPlan" value="auto" checked={isSearching === "auto"} />
            <label onClick={() => { setIsSearching('auto') }} className='lx-flex lx-items-center lx-opacity-[0.5] lx-gap-2 lx-p-[12px_16px] lx-rounded-[999px] lx-leading-[0] lx-transition-all lx-duration-300 lx-cursor-pointer lx-text-[#323335] lx-text-[16px] lx-font-medium lx-lading-[22px] lx-font-body_font lx-justify-center' htmlFor='for="switchEnhanced"'>
              <AiStarIcon width='20' height='20' color='#000' />
              <span className='lx-text-[#323335] lx-text-[16px] lx-font-medium lx-lading-[22px] lx-font-body_font'>AI Auto Search</span>
            </label>
          </div>
          <div className='lx-flex-none [&_input:checked~label_svg_path]:lx-fill-[#323335] [&_input:checked~label]:lx-opacity-[1] [&_input:checked~label]:lx-bg-[#fff] [&_input:checked~label]:lx-shadow-[0px_0.775px_1.245px_0px_rgba(0,0,0,0.01),0px_1.862px_2.993px_0px_rgba(0,0,0,0.01),0px_3.506px_5.635px_0px_rgba(0,0,0,0.01),0px_6.254px_10.051px_0px_rgba(0,0,0,0.02),0px_11.698px_18.8px_0px_rgba(0,0,0,0.02),0px_28px_45px_0px_rgba(0,0,0,0.03)]' >
            <input className='lx-hidden' type="radio" checked={isSearching == "manual"} id="switchTraditional" name="switchPlan" value="manual" />
            <label onClick={() => { setIsSearching('manual') }} className='lx-flex lx-items-center lx-opacity-[0.5] lx-gap-2 lx-p-[12px_16px] lx-rounded-[999px] lx-leading-[0] lx-transition-all lx-duration-300 lx-cursor-pointer lx-text-[#323335] lx-text-[16px] lx-font-medium lx-lading-[22px] lx-font-body_font' htmlFor='switchTraditional'>
              <SearchIcon />
              <span className='lx-text-[#323335] lx-text-[16px] lx-font-medium lx-lading-[22px] lx-font-body_font'>Manual Search</span>
            </label>
          </div>
          <div className='lx-flex-none [&_input:checked~label_svg_path]:lx-fill-[#323335] [&_input:checked~label]:lx-opacity-[1] [&_input:checked~label]:lx-bg-[#fff] [&_input:checked~label]:lx-shadow-[0px_0.775px_1.245px_0px_rgba(0,0,0,0.01),0px_1.862px_2.993px_0px_rgba(0,0,0,0.01),0px_3.506px_5.635px_0px_rgba(0,0,0,0.01),0px_6.254px_10.051px_0px_rgba(0,0,0,0.02),0px_11.698px_18.8px_0px_rgba(0,0,0,0.02),0px_28px_45px_0px_rgba(0,0,0,0.03)]' >
            <input className='lx-hidden' type="radio" checked={isSearching == "prompt"} id="switchTraditional" name="switchPlan" value="manual" />
            <label onClick={() => { setIsSearching('prompt') }} className='lx-flex lx-items-center lx-opacity-[0.5] lx-gap-2 lx-p-[12px_16px] lx-rounded-[999px] lx-leading-[0] lx-transition-all lx-duration-300 lx-cursor-pointer lx-text-[#323335] lx-text-[16px] lx-font-medium lx-lading-[22px] lx-font-body_font' htmlFor='switchTraditional'>
              <SearchIcon />
              <span className='lx-text-[#323335] lx-text-[16px] lx-font-medium lx-lading-[22px] lx-font-body_font'>AI Recommended Search</span>
            </label>
          </div>
        </div>
        <span className='lx-flex lx-ml-auto  lx-h-[52px] lx-w-[52px] lx-items-center lx-justify-center lx-cursor-pointer lg:lx-absolute lg:lx-top-[20px] lg:lx-right-[20px]'><CloseIcon /></span>
      </div>
      <div className='lx-p-[0_40px] lx-mt-[8px] lx-h-[calc(100vh-192px)] lg:lx-h-[calc(100vh-236px)] md:lx-h-[calc(100vh-321px)] lx-overflow-y-auto lx-overflow-x-hidden xs:lx-px-[20px]'>
        <div className=' lx-m-[0_auto] lx-max-w-[759px] lx-w-full lx-text-center'>
          <h2 className='lx-text-[#4D4F51] lx-text-[24px] lx-leading-[31px] lx-font-medium lx-font-heading_font lx-tracking-[-0.12px]'>{isSearching == "auto" ? "AI Auto" : (isSearching == "prompt" ? "AI Recommended" : "Manual")} Search</h2>
          <p className='lx-mt-1 lx-text-[#8C8E91] lx-text-[16px] lx-leading-[22px] lx-font-normal lx-font-body_font'>Explore all vetted Learnexus talent for your next project</p>
        </div>
        <div className='lx-mt-8'>
          {isSearching == "manual" &&
            <ManualSearch isLoading={isLoading} setIsLoading={setIsLoading} recommendTalent={recommendTalent} />
          }

          {isSearching == "auto" &&
            <AiSearch isLoading={isLoading} setIsLoading={setIsLoading} recommendTalent={recommendTalent} />
          }
          {
            isSearching == "prompt" &&
            <PromptSeacrh isLoading={isLoading} setIsLoading={setIsLoading} textResponse={textResposnse} recommendTalent={recommendTalent} />
          }

        </div>

        {
          requestLoadingLevel != null &&
          <div className='lx-mt-8 lx-mx-[auto] lx-rounded-[50px] lx-p-[14px_30px] lx-bg-[rgba(110,110,110,0.06)] lx-flex lx-items-center lx-justify-between lx-max-w-[720px] lx-w-full '>
            <div className={` ${requestLoadingLevel == 1 ? 'lx-pending' : 'lx-complete'} lx-flex lx-flex-none lx-items-center lx-gap-[10px] [&.lx-complete>span]:lx-text-[#03A071] [&.lx-complete>svg_path]:lx-fill-[#03A071]`}>
              {requestLoadingLevel > 1 && <CheckIcon width='20' height='20' color='#000' />}
              {requestLoadingLevel == 1 && <LoaderIcon />}

              <span className='lx-flex-none lx-text-[#8C8E91] lx-text-[15px] lx-font-normal lx-leading-[20px] lx-tracking-[-0.075px]'>Finding Talent</span>
            </div>
            <div className={` ${requestLoadingLevel == 2 && 'lx-pending'} ${requestLoadingLevel > 1 && 'lx-complete'} lx-flex lx-flex-none lx-items-center lx-gap-[10px] [&.lx-complete>span]:lx-text-[#03A071] [&.lx-complete>svg_path]:lx-fill-[#03A071]`}>
              {requestLoadingLevel > 2 && <CheckIcon width='20' height='20' color='#000' />}
              {requestLoadingLevel == 2 && <LoaderIcon />}

              <span className='lx-flex-none lx-text-[#8C8E91] lx-text-[15px] lx-font-normal lx-leading-[20px] lx-tracking-[-0.075px]'>Preparing Talent Details</span>
            </div>

            <div className={` ${requestLoadingLevel == 3 && 'lx-pending'} ${requestLoadingLevel > 2 && 'lx-complete'} lx-flex lx-flex-none lx-items-center lx-gap-[10px] [&.lx-complete>span]:lx-text-[#03A071] [&.lx-complete>svg_path]:lx-fill-[#03A071]`}>
              {requestLoadingLevel > 3 && <CheckIcon width='20' height='20' color='#000' />}
              {requestLoadingLevel == 3 &&
                <div className="lx-relative lx-text-base lx-flex lx-items-center lx-gap-1.5 lx-opacity-0 lx-animate-[dot-loading_2.5s_ease-in-out_infinite]">
                  <span className="lx-flex-none lx-w-[4.138px] lx-h-[4.138px] lx-bg-[#C6C6CA] lx-rounded-[50%] [animation-delay:0.2s]"></span>
                  <span className="lx-flex-none lx-w-[5.517px] lx-h-[5.517px] lx-bg-[#C6C6CA] lx-rounded-[50%] [animation-delay:0.4s]"></span>
                  <span className="lx-flex-none lx-w-[2.759px] lx-h-[2.759px] lx-bg-[#C6C6CA] lx-rounded-[50%] [animation-delay:0.6s]"></span>
                </div>}
              <span className='lx-flex-none lx-text-[#8C8E91] lx-text-[15px] lx-font-normal lx-leading-[20px] lx-tracking-[-0.075px]'>Generating Email</span>
            </div>
          </div>
        }
        {
          isSearching == 'prompt' &&
          <>
            {
              textResposnse &&
              <ul className="lx-max-w-[1050px] lx-w-full lx-m-[64px_auto_0_auto] lx-pb-10">
                {
                  textResposnse.map((item: any, index: number) => {
                    return (
                      <li >
                        <div className="lx-flex lx-items-center lx-p-[20px] lx-transition-all lx-duration-300 hover:lx-bg-[#F9FAFB] lx-talent_profile lx-shadow-[inset_0_-1px_#e5e7eb] md:lx-flex-wrap md:lx-gap-[15px] md:lx-px-[10px]">
                          <figure className="lx-userplaceholder lx-rounded-[10px] lx-w-[168px] lx-flex-none lx-h-[168px] lx-self-start lx-shimmer">
                            <img src={item?.profile_image} className="lx-w-[168px] lx-h-[168px] lx-object-cover lx-rounded-[10px]" alt="images" />
                          </figure>
                          <a target='_blank' href={`https://learnexus.com/talent/${item?.user_id}`} className="lx-pl-[20px] lx-w-full md:lx-p-[0] md:lx-w-full">
                            <div className="lx-flex lx-gap-[15px] lx-userprofile lg:lx-flex-col lg:lx-items-start">
                              <div className="lx-flex-auto lx-w-full">
                                <span className="lx-inline-flex lx-gap-[4px]  lx-items-center lx-text-[18px] lx-cursor-pointer lx-leading-[22px] lx-font-semibold lx-text-[#101828] lx-m-[0_0_6px]">
                                  {item?.full_name}
                                </span>
                                <div className="lx-flex lx-items-center lx-gap-[8px_24px] lx-mt-[6px] sm:lx-flex-col sm:lx-gap-[10px] sm:lx-items-start lx-flex-wrap">
                                  <span className="lx-flex lx-gap-[6px] lx-items-center lx-text-[14px] lx-leading-[20px] lx-text-[#4B5563] lx-flex-none">
                                    <i className="lx-text-[#9CA3AF] lx-text-[16px] fa-regular fa-location-dot"></i>
                                    <span>{item?.address?.address}</span>
                                  </span>
                                  <span className="lx-flex lx-gap-[6px] lx-items-center lx-text-[14px] lx-leading-[20px] lx-text-[#4B5563] lx-flex-none">
                                    <i className="lx-text-[#9CA3AF] lx-text-[16px] fa-regular fa-dna"></i> {item?.score}% machted
                                  </span>
                                </div>
                                <p className="lx-text-[15px] lx-text-[#0D0C22] lx-mt-[16px] lx-leading-[20px] sm:lx-mt-2.5 lx-lineclamp">
                                  <span>{item?.tagline}</span>
                                </p>
                              </div>
                            </div>
                            {/* <div className="lx-mt-[16px] sm:lx-mt-2.5">
                              <ul className="lx-flex lx-items-center lx-flex-wrap lx-w-full lx-gap-[14px] sm:lx-gap-2.5">
                                <li>
                                  <span className="lx-text-[#073D3D] lx-border-solid lx-border-[1px] lx-border-[#D1D5DB] lx-text-[14px] lx-leading-[20px] lx-rounded-[100px] lx-block lx-p-[3px_9px] lx-text-center [&_mark]:lx-text-[#073D3D] [&_mark]:lx-font-semibold">Assessment Validation</span>
                                </li>
                                <li className="lx-list-none lx-p-[5px]">
                                  <button className="lx-block lx-outline-none lx-text-[14px] lx-leading-[20px] lx-text-[#073D3D] lx-cursor-pointer">+03 More</button>
                                </li>
                              </ul>
                            </div> */}
                          </a>
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
            }
            {!textResposnse && !isLoading &&
              <div className=" lx-bg-[#F9FAFB] lx-text-center lx-rounded-6 lx-grid lx-place-content-center lx-min-h-[300px] lx-p-[30px] lx-max-w-[1050px] lx-w-full lx-m-[64px_auto_0_auto]">
                <p className="lx-text-[#4B5563] lx-text-[18px] lx-leading-[21px] lx-font-semibold">No search result found</p>
                <span className="lx-mt-[8px] lx-text-[#4B5563] lx-text-[15px] lx-leading-[18px] lx-font-normal">Hit the reset search button to reset search to default</span>
              </div>
            }
          </>

        }

        {resposnse && <div className='lx-mt-[50px] lx-flex lx-items-start lx-gap-[24px] lx-max-w-[1134px] lx-w-full lx-ml-[191px] xxxl:lx-mx-[auto]'>
          <div className='lx-rounded-[20px_20px_0_0] lx-bg-[#fff] lx-w-full'>
            <div dangerouslySetInnerHTML={{ __html: resposnse.message }} ref={responseRef} contentEditable={true} className='lx-w-full lx-p-[20px_15px_20px_30px] lx-m-[10px_15px_10px_0] lx-h-[calc(100vh-500px)]  lx-scroll-hover lx-overflow-y-auto lx-overflow-x-hidden'>
            </div>

          </div>
          <div className='lx-block md:lx-w-full'>
            <span onClick={() => { copyResposnse() }} className='lx-cursor-pointer lx-w-[60px] lx-h-[60px] lx-gap-[20px] lx-flex lx-items-center lx-justify-center lx-bg-[#fff] lx-rounded-[50%] lx-shadow-[0px_0.718px_5.023px_0px_rgba(0,0,0,0.01),0px_1.63px_11.413px_0px_rgba(0,0,0,0.01),0px_2.838px_19.863px_0px_rgba(0,0,0,0.02),0px_4.508px_31.554px_0px_rgba(0,0,0,0.02),0px_6.955px_48.686px_0px_rgba(0,0,0,0.02),0px_10.853px_75.971px_0px_rgba(0,0,0,0.03),0px_18.018px_126.123px_0px_rgba(0,0,0,0.03),0px_36px_252px_0px_rgba(0,0,0,0.04)] md:lx-w-full md:lx-h-full md:lx-rounded-[20px_20px_0_0] md:lx-p-[10px]'>
              <CopyIcon />
              <span className='lx-text-[#323335] lx-text-[16px] lx-leading-[28.8px] lx-font-normal lx-font-body_font lx-hidden md:lx-block'>copy</span>
            </span>
          </div>


        </div>}
      </div>
    </div>

  )
}

export default RecommendTalent